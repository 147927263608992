export default {
  siteTitle: 'Get bonus',
  blogerName: 'BDSLOTS',
  socialsList: [
    {
      name: 'youtube',
      url: 'https://www.youtube.com/@bdslots18'
    },
    {
      name: 'instagram',
      url: 'https://www.instagram.com/biancad_ofc/'
    },
    {
      name: 'telegram',
      url: 'https://t.me/BDSLOTSs1'
    }
  ],
  projects: [
    {
      name: '1go',
      url: 'https://1gocasino.life/cd0c42f7e',
      gameTitle: 'Book of Kemet (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'drip',
      url: 'https://dripcasino.life/c260a1293',
      gameTitle: 'Alien Fruits',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'monro',
      url: 'https://monrocasino.life/ce46c6d9d',
      gameTitle: 'Burning Chilli X (BGaming)',
      casinoBonus1: '<strong>150%</strong><div>No primeiro depósito</div>',
    },
    {
      name: 'starda',
      url: 'https://stardacasino.life/c089e8c07',
      gameTitle: 'STARDA QUEEN (BGaming)',
    },
    {
      name: 'legzo',
      url: 'https://legzocasino.life/c13018876',
      gameTitle: 'LEGZO PUNK (BGaming)',
    }
  ],
  headerText: 'Cadastre-se com um código promocional <strong>BDSLOTS</strong> e receba 50 rodadas grátis nos jogos abaixo',
  yourPromocode: 'Seu código promocional',
  gameBonus: 'Bónus no jogo',
  promocode: 'BDSLOTS',
  casinoBonus1: '<strong>100%</strong><div>No primeiro depósito</div>',
  casinoBonus2: '<strong>50FS</strong><div>Para cadastro com código promocional</div>',
  firstDeposit: 'DE PRIMEIRO DEPÓSITO',
  copyMessage: 'Código promocional copiado',
  theme: 'brazil'
};
